import React from "react"

const Circle = props => (
  <svg
    style={{
      width: props.diameter + "px",
      height: props.diameter + "px",
      right: `${props.center - 800}px`,
      bottom: `${props.center - 300}px`,
    }}
    fill="none"
    viewBox={`0 0 1204 1204`}
  >
    <defs />
    <path
      stroke="#000"
      strokeDasharray="0 30"
      strokeLinecap="round"
      strokeMiterlimit="10"
      strokeWidth={props.sWidth}
      d="M602 1202c331.371 0 600-268.629 600-600S933.371 2 602 2 2 270.629 2 602s268.629 600 600 600z"
    />
  </svg>
)

export default Circle
