import React from "react"

const Drops = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 250 390"
    style={{
      width: "250px",
      right: "-180px",
      bottom: "-120px",
    }}
  >
    <defs />
    <path
      fill="#0439D9"
      fill-rule="evenodd"
      d="M57.2009 227.1c4.6-33.4-14.6-53.1-39.9-69.2-17.599967-52.4-27.19996-105-1.8-157.9 5.4 2.2 10.7 4.4 16 6.6 53.8 50.8 98.0001 106.5 96.2001 186.1-9.1 22-17.5 44.7-43.3001 52.9-12.9-.6-22-6.7-27.2-18.5z"
      clip-rule="evenodd"
    />
    <path
      fill="#F20505"
      fill-rule="evenodd"
      d="M171.201 364c-30.6-39.9-88.7002-60.8-85.4002-124 .1-17.3-1.2-35.2 18.7002-44.1 5 2.1 10.1 4.2 15.1 6.3 6.7 3.1 12.8 7 18.6 11.6 17.7 52.8 85.2 59.3 103.3 111.7 7.7 19.7 14.5 39.2-4.6 56.6-27.2 14-49.3 8.1-65.7-18.1z"
      clip-rule="evenodd"
    />
  </svg>
)

export default Drops
