import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { Listing, Wrapper, Title } from "../components"
import website from "../../config/website"
import { LocaleContext } from "../components/Layout"
import SEO from "../components/SEO"
import Circle from "../components/Circle"
import Drops from "../components/Drops"

const Hero = styled.header`
  display: flex;
  align-items: center;
  height: 100vh;
  overflow: hidden;
`

const HeroInner = styled(Wrapper)`
  padding-top: 14rem;
  padding-bottom: 13rem;
  h1 {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding-top: 14rem;
    padding-bottom: 10rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding-top: 14rem;
    padding-bottom: 8rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 14rem;
    padding-bottom: 6rem;
  }
`

const CircleBackground = styled.div`
  position: relative;

  & svg {
    position: absolute;
    overflow: visible;
    z-index: -1;
  }
`

const HeroText = styled.div`
  font-size: 1.4rem;
  line-height: 1.4;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
  color: ${props => props.theme.colors.black};
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 1.4rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.25rem;
  }
`

const Social = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  font-family: "Source Sans Pro", -apple-system, "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  li {
    display: inline;
    &:not([data-name="social-entry-0"]) {
      margin-left: 2.5rem;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        margin-left: 1.75rem;
      }
    }
    a {
      font-style: normal;
      color: ${props => props.theme.colors.black};
      font-size: 1.333rem;
      font-weight: 600;
      &:hover,
      &:focus {
        color: ${props => props.theme.colors.black};
        text-decoration: underline;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.2rem;
      }
    }
  }
`

const ProjectListing = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 4rem;
  li {
    margin-bottom: 1.45rem;
    a {
      font-size: 2.369rem;
      font-style: normal;
      color: ${props => props.theme.colors.black};
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 1.777rem;
      }
    }
  }
`

const ring = () => {
  for (let i = 0; i < 11; i++)
    return <Circle sWidth="2.9221" diameter="1100" center="50" />
}

const IndexWrapper = Wrapper.withComponent("main")

const Index = ({
  data: { homepage, social, posts, projects },
  pageContext: { locale },
  location,
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  return (
    <>
      <SEO pathname={location.pathname} locale={locale} />
      <Hero>
        <HeroInner>
          <h1>{homepage.data.title.text}</h1>
          <HeroText
            dangerouslySetInnerHTML={{ __html: homepage.data.content.html }}
          />
          <Social>
            {social.edges[0].node.data.body.map((s, index) => (
              <li
                data-name={`social-entry-${index}`}
                key={s.primary.label.text}
              >
                <a href={s.primary.link.url}>{s.primary.label.text}</a>
              </li>
            ))}
          </Social>
          <CircleBackground>
            {console.log(ring)}
            {ring()}
            <Circle sWidth="3" diameter="1200" center="0" />
            <Circle sWidth="3" diameter="1150" center="25" />
            <Circle sWidth="3" diameter="1100" center="50" />
            <Circle sWidth="3" diameter="1050" center="75" />
            <Circle sWidth="3" diameter="1000" center="100" />
            <Circle sWidth="3" diameter="950" center="125" />
            <Circle sWidth="3" diameter="900" center="150" />
            <Circle sWidth="3" diameter="850" center="175" />
            <Circle sWidth="3" diameter="800" center="200" />
            <Circle sWidth="3" diameter="750" center="225" />
            <Drops />
          </CircleBackground>
        </HeroInner>
      </Hero>
      <IndexWrapper
        id={website.skipNavId}
        style={{ paddingTop: "2rem", paddingBottom: "2rem" }}
      >
        <Title style={{ marginTop: "8rem" }}>
          {i18n.recent} {i18n.projects}
        </Title>
        <ProjectListing>
          {projects.edges[0].node.data.body.map(project => (
            <li key={project.primary.label.text}>
              <a
                href={project.primary.link.url}
                target="_blank"
                rel="noreferrer"
              >
                {project.primary.label.text}
              </a>
            </li>
          ))}
        </ProjectListing>
      </IndexWrapper>
    </>
  )
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    posts: PropTypes.object.isRequired,
    homepage: PropTypes.object.isRequired,
    social: PropTypes.object.isRequired,
    projects: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    homepage: prismicHomepage(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
    social: allPrismicHeroLinks(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            body {
              primary {
                label {
                  text
                }
                link {
                  url
                }
              }
            }
          }
        }
      }
    }
    posts: allPrismicPost(
      sort: { fields: [data___date], order: DESC }
      filter: { lang: { eq: $locale } }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    projects: allPrismicProjects(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            body {
              primary {
                label {
                  text
                }
                link {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
